import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import styled from "@emotion/styled"

import Layout from "../components/layout"
import dsm from "../utils/dsm"
import { device } from "../utils/mixins"
import { homePageMetadata } from "../utils/seo"

import {
  FullSizeImgCont,
  SectionContentContainer,
  SectionContainerNoPad,
  IconTitleCont,
  OurProcessCont,
  OurProcessContRight,
  WhereCont,
} from "../components/imageComponents"
import {
  I2BronzeText,
  SectionTitleText,
  SectionTitleTxt,
  SectionTitleWrapped,
  BodyText,
  BodyBold,
  BodyBoldBronze,
  BodyLink,
} from "../components/textComponents"
import {
  ImageButtonHome,
  SectionButton,
} from "../components/interactiveComponents"

import SEO from "../components/seo"

var FullSectionTitle = styled.h3`
  ${dsm.fonts.primary};
  display: none;
  font-weight: 750;
  text-shadow: 0.1rem 0.1rem 0.3rem rgba(0, 0, 0, 0.2);
  margin-top: 0;
  margin-bottom: 1.25rem;

  ${device.tablet} {
    display: block;
    font-size: 1.85rem;
  }
`

var MobileSectionTitle = styled(SectionTitleTxt)`
  display: block;
  ${device.tablet} {
    display: none;
  }
`

var Image = styled(Img)`
  max-height: 15rem;
  position: absolute;

  ${device.mobileX} {
    max-height: 20rem;
  }

  ${device.tablet} {
    max-height: 23rem;
  }
`

var TopImageText = styled.h3`
  ${dsm.fonts.primary};
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: 0.5rem;
  text-shadow: 0.1rem 0.1rem 0.3rem rgba(0, 0, 0, 0.2);
  padding: 0 0.5rem;

  ${device.mobileL} {
    font-size: 1.35rem;
  }

  ${device.tablet} {
    display: none;
    padding: 0;
  }
`

var TopImageTextTwo = styled(TopImageText)`
  display: none;
  ${device.tablet} {
    display: block;
    position: absolute;
    bottom: 0.5rem;
    padding: 0.35rem 1.5rem 0.35rem 0.25rem;
    border-radius: 0 1rem 1rem 0;
    background: rgba(0, 0, 0, 0.75);
    color: ${dsm.color.brand.copper.base};
    width: 32rem;
    font-size: 1.5rem;
  }

  ${device.laptop} {
    width: 40rem;
    font-size: 1.75rem;
    padding-left: 0.75rem;
  }
`

var OurProcessText = styled.h4`
  ${dsm.fonts.primary};
  font-size: 1.25rem;
  margin: 0 0.5rem;

  ${device.mobileM} {
    font-size: 1.35rem;
  }

  ${device.mobileX} {
    font-size: 1.45rem;
  }

  ${device.tablet} {
    margin-top: 1.5rem;
  }
`

var WhereSeasonTitle = styled.h4`
  ${dsm.fonts.primary};
  font-size: 1.35rem;
  margin: 1.25rem 0 0.25rem;

  ${device.mobileM} {
    font-size: 1.4rem;
  }

  ${device.mobileX} {
    font-size: 1.5rem;
  }
`

var WhereItemsContainer = styled.div`
  ${device.tablet} {
    display: flex;
    flex-wrap: wrap;
  }
`

var WhereSeasonTitleUnderline = styled.div`
  height: 0.1rem;
  background: black;
  width: 100%;
`

function IndexPage() {
  // prettier-ignore
  // Query format: data.i2TopImg.sharp.fluid
  // Helpers in imageHelper.js
  var data = useStaticQuery(graphql`
    query {
      i2TopImg: file(relativePath: { eq: "i2Optimization.jpeg" }) {
        ...fluidImageSVG
      }

      i2ExploreImg: file(
        relativePath: { eq: "DrillingOptimizationConsultant.jpeg" }) {
        ...fluidImage
      }

      i2EvaluateImg: file(relativePath: { eq: "CompletionsCostSavings.jpeg" }) {
        ...fluidImage
      }

      i2ExecuteImg: file(relativePath: { eq: "OilAndGasOptimizationConsultant.jpeg" }) {
        ...fluidImage
      }

      i2WhereImgOne: file(relativePath: {eq: "OilAndGasDrillingOptimization.jpeg"}) {
        ...fluidImage
      }

      i2WhereImgTwo: file(relativePath: {eq: "OilAndGasCompletionsOptimization.jpeg"}) {
        ...fluidImage
      }

      i2WhereImgThree: file(relativePath: {eq: "OilAndGasProductionOptimization.jpeg"}) {
        ...fluidImage
      }

    }
  `)

  return (
    <Layout>
      <SEO
        title="Oil & Gas Optimization"
        description="i2 Optimization USA/Canada. Specializing in the implementation of cutting edge Drilling, Production, and Completions technology."
        meta={homePageMetadata}
      />

      <TopImageText>
        What could a <I2BronzeText>10%+</I2BronzeText> Efficiency Increase Do
        For <I2BronzeText>Your Operation?</I2BronzeText>
      </TopImageText>

      <FullSizeImgCont>
        <Image
          style={{ height: "inherit" }}
          objectFit="cover"
          fluid={data.i2TopImg.sharp.fluid}
          alt="i2 - The Drilling, Completions, and Production Optimization Company."
        />

        <ImageButtonHome to="/contact-us/">Contact Us</ImageButtonHome>

        <TopImageTextTwo>
          What could a 10%+ Efficiency Increase Do For Your Operation?
        </TopImageTextTwo>
      </FullSizeImgCont>

      <SectionContentContainer>
        <MobileSectionTitle>
          Global Ideas <br />
          <SectionTitleWrapped>Local Solutions</SectionTitleWrapped>
        </MobileSectionTitle>

        <FullSectionTitle>
          More Output
          <SectionTitleWrapped>Less Resources</SectionTitleWrapped>
        </FullSectionTitle>

        <BodyText>
          Since 1995, our team has helped streamline{" "}
          <BodyBold>Drilling, Completions,</BodyBold> and&nbsp;
          <BodyBold>Production</BodyBold> operations for clients across North
          America.
        </BodyText>

        <BodyText>
          Applying our experience and industry innovation,{" "}
          <BodyBoldBronze>
            Our Mission is to Help You Get More From Your Operation With Less
            Resources.
          </BodyBoldBronze>
        </BodyText>
      </SectionContentContainer>

      <SectionContainerNoPad>
        <IconTitleCont>
          Our <I2BronzeText>Process</I2BronzeText>
        </IconTitleCont>

        <OurProcessCont
          title="01. Explore"
          desc="It all starts with a thorough examination of your site(s). We identify your challenges and processes with potential for significant efficiency gains."
        >
          <Image
            style={{ height: "inherit" }}
            objectFit="cover"
            fluid={data.i2ExploreImg.sharp.fluid}
            alt="i2O - The Drilling, Completions, and Production Optimization Company."
          />
        </OurProcessCont>

        <OurProcessContRight
          title="02. Evaluate"
          desc="We provide a full report identifying optimization opportunities, a detailed implementation plan, and expected cost/resource savings."
        >
          <Image
            style={{ height: "inherit" }}
            objectFit="cover"
            fluid={data.i2EvaluateImg.sharp.fluid}
            alt="i2O - The Drilling, Completions, and Production Optimization Company."
          />
        </OurProcessContRight>

        <OurProcessCont
          title="03. Execute"
          desc="We analyze the effectiveness of the optimizations and how they have impacted operations. Solutions are identified for implementation issues."
        >
          <Image
            style={{ height: "inherit" }}
            objectFit="cover"
            fluid={data.i2ExecuteImg.sharp.fluid}
            alt="i2 - The Drilling, Completions, and Production Optimization Company."
          />
        </OurProcessCont>

        <OurProcessText>
          Steps 01 - 03 Are Repeated{" "}
          <BodyBoldBronze>Until Efficiency Targets Are Met.</BodyBoldBronze>
        </OurProcessText>

        <SectionButton to="/contact-us/">Contact Us</SectionButton>
      </SectionContainerNoPad>

      <SectionContentContainer>
        <SectionTitleText>
          Where We're
          <SectionTitleWrapped>Optimizing Now</SectionTitleWrapped>
        </SectionTitleText>

        <WhereSeasonTitle>
          <BodyBoldBronze>FALL</BodyBoldBronze> 2020
        </WhereSeasonTitle>
        <WhereSeasonTitleUnderline />

        <WhereItemsContainer>
          <WhereCont
            location="DJ Basin"
            geo="Colorado, USA"
            category="Completions"
          >
            <Image
              style={{ height: "inherit" }}
              fluid={data.i2WhereImgOne.sharp.fluid}
              alt="i2 - The Drilling, Completions, and Production Optimization Company."
            />
          </WhereCont>

          <WhereCont
            location="Permian Basin"
            geo="West Texas, USA"
            category="Completions"
          >
            <Image
              style={{ height: "inherit" }}
              fluid={data.i2WhereImgTwo.sharp.fluid}
              alt="i2 - The Drilling, Completions, and Production Optimization Company."
            />
          </WhereCont>
        </WhereItemsContainer>

        <WhereSeasonTitle>
          <BodyBoldBronze>SPRING</BodyBoldBronze> 2021
        </WhereSeasonTitle>
        <WhereSeasonTitleUnderline />

        <WhereCont
          location="Marcellus Shale"
          geo="Pennsylvania, USA"
          category="Completions"
        >
          <Image
            style={{ height: "inherit" }}
            fluid={data.i2WhereImgThree.sharp.fluid}
            alt="i2 - The Drilling, Completions, and Production Optimization Company."
          />
        </WhereCont>

        <BodyText>
          Our Services Are Currently Booked Until Spring 2021.
        </BodyText>

        <BodyText>
          <BodyLink to="/contact-us/">Contact Us</BodyLink> to add your
          operation to our 2021 schedule.
        </BodyText>
      </SectionContentContainer>
    </Layout>
  )
}

export default IndexPage
