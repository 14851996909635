export var homePageMetadata = [
  {
    property: `og:image`,
    content:
      "https://res.cloudinary.com/dxmbnxuho/image/upload/v1571255384/i2_Optimization_Oil_and_Gas_Drilling_Production_Completions.jpg",
  },
  {
    property: `og:image:type`,
    content: "image/jpeg",
  },
  {
    property: `og:image:width`,
    content: `1280`,
  },
  {
    property: `og:image:height`,
    content: `854`,
  },
  {
    property: `og:image:alt`,
    content: `i2 Optimization - Modernizing the Energy Industry with the Latest Tech. Drilling, Production, and Completions.`,
  },
  {
    name: `twitter:image`,
    content: `https://res.cloudinary.com/dxmbnxuho/image/upload/v1571344248/i2_Optimization_Oil_and_Gas_Drilling_Production_Completions-tw.jpg`,
  },
]

// TODO: Add About Specific Content
export var aboutPageMetadata = [
  {
    property: `og:image`,
    content:
      "https://res.cloudinary.com/dxmbnxuho/image/upload/v1571255384/i2_Optimization_Oil_and_Gas_Drilling_Production_Completions.jpg",
  },
  {
    property: `og:image:type`,
    content: "image/jpeg",
  },
  {
    property: `og:image:width`,
    content: `1280`,
  },
  {
    property: `og:image:height`,
    content: `854`,
  },
  {
    property: `og:image:alt`,
    content: `i2 Optimization - Modernizing the Energy Industry with the Latest Tech. Drilling, Production, and Completions.`,
  },
  {
    name: `twitter:image`,
    content: `https://res.cloudinary.com/dxmbnxuho/image/upload/v1571344248/i2_Optimization_Oil_and_Gas_Drilling_Production_Completions-tw.jpg`,
  },
]

export var caseStudiesPageMetadata = [
  {
    property: `og:image`,
    content:
      "https://res.cloudinary.com/dxmbnxuho/image/upload/v1571255384/i2_Optimization_Oil_and_Gas_Drilling_Production_Completions.jpg",
  },
  {
    property: `og:image:type`,
    content: "image/jpeg",
  },
  {
    property: `og:image:width`,
    content: `1280`,
  },
  {
    property: `og:image:height`,
    content: `854`,
  },
  {
    property: `og:image:alt`,
    content: `i2 Optimization - Modernizing the Energy Industry with the Latest Tech. Drilling, Production, and Completions.`,
  },
  {
    name: `twitter:image`,
    content: `https://res.cloudinary.com/dxmbnxuho/image/upload/v1571344248/i2_Optimization_Oil_and_Gas_Drilling_Production_Completions-tw.jpg`,
  },
]

export var contactUsPageMetadata = [
  {
    property: `og:image`,
    content:
      "https://res.cloudinary.com/dxmbnxuho/image/upload/v1571255384/i2_Optimization_Oil_and_Gas_Drilling_Production_Completions.jpg",
  },
  {
    property: `og:image:type`,
    content: "image/jpeg",
  },
  {
    property: `og:image:width`,
    content: `1280`,
  },
  {
    property: `og:image:height`,
    content: `854`,
  },
  {
    property: `og:image:alt`,
    content: `i2 Optimization - Modernizing the Energy Industry with the Latest Tech. Drilling, Production, and Completions.`,
  },
  {
    name: `twitter:image`,
    content: `https://res.cloudinary.com/dxmbnxuho/image/upload/v1571344248/i2_Optimization_Oil_and_Gas_Drilling_Production_Completions-tw.jpg`,
  },
]

export var faqsPageMetadata = [
  {
    property: `og:image`,
    content:
      "https://res.cloudinary.com/dxmbnxuho/image/upload/v1571255384/i2_Optimization_Oil_and_Gas_Drilling_Production_Completions.jpg",
  },
  {
    property: `og:image:type`,
    content: "image/jpeg",
  },
  {
    property: `og:image:width`,
    content: `1280`,
  },
  {
    property: `og:image:height`,
    content: `854`,
  },
  {
    property: `og:image:alt`,
    content: `i2 Optimization - Modernizing the Energy Industry with the Latest Tech. Drilling, Production, and Completions.`,
  },
  {
    name: `twitter:image`,
    content: `https://res.cloudinary.com/dxmbnxuho/image/upload/v1571344248/i2_Optimization_Oil_and_Gas_Drilling_Production_Completions-tw.jpg`,
  },
]

export var industryExpPageMetadata = [
  {
    property: `og:image`,
    content:
      "https://res.cloudinary.com/dxmbnxuho/image/upload/v1571255384/i2_Optimization_Oil_and_Gas_Drilling_Production_Completions.jpg",
  },
  {
    property: `og:image:type`,
    content: "image/jpeg",
  },
  {
    property: `og:image:width`,
    content: `1280`,
  },
  {
    property: `og:image:height`,
    content: `854`,
  },
  {
    property: `og:image:alt`,
    content: `i2 Optimization - Modernizing the Energy Industry with the Latest Tech. Drilling, Production, and Completions.`,
  },
  {
    name: `twitter:image`,
    content: `https://res.cloudinary.com/dxmbnxuho/image/upload/v1571344248/i2_Optimization_Oil_and_Gas_Drilling_Production_Completions-tw.jpg`,
  },
]
